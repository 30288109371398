import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";
import BanerMDD from "../../components/BanerMDD/BanerMDD";

const Horizon = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Horizon",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #b2976b, #c0a477, #ccaf80, #c0a477, #b2976b )",
        icons: "#c0a477",
        navClass: "horizon",
        ogImage: require("../../assets/img/portfolio/horizon_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/horizon/",
      }}
    >
      <PortfolioHeader name="horizon" height="176" />
      <section className="container-fluid horizon_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Hotel Horizon</h1>
              <ul>
                <li>Naming</li>
                <li>Key Visual</li>
                <li>Branding</li>
                <li>Copywriting</li>
                <li>Landing Page</li>
                <li>Strona internetowa</li>
                <li>Banery statyczne</li>
                <li>Banery dynamiczne</li>
                <li>Kampania leadowa</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Firma WPROST Nieruchomości, zgłosiła się do nas z prośbą o
                wsparcie dla swojego Klienta, grupy kapitałowej AKME Zdzisław
                Wiśniewski. AKME Zdzisław Wiśniewski to największa w kraju firma
                łącząca usługi budowlane z działalnością archeologiczną, którą
                mieliśmy wesprzeć w realizacji nowej inwestycji - hotelu w
                systemie condo, umiejscowionego w topowej lokalizacji w
                Szklarskiej Porębie.
              </p>
              <p>
                Obiekt realizowany w czterogwiazdkowym standardzie, zapewniający
                niesamowity widok na panoramę Karkonoszy - również z poziomu
                basenu infinity.
              </p>
              <p>
                Naszym zadaniem było.. niemal wszystko. Po szczegółowym
                briefingu, ustaliliśmy, że naszym zadaniem będzie
                zaprojektowanie nazwy hotelu, keyvisuala, logo oraz innych
                elementów identyfikacji wizualnej, strony landing page na
                potrzeby generowanie leadów (condohotel, docelowej, pełnej
                strony inwestycji oraz zaprojektowanie oraz prowadzenie kampanii
                lead generation.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid horizon_section_3">
        <div className="row">
          <div className="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/horizon_bar.png")}
              alt=""
            />
            <div className="img_wrapper">
              <div className="img_group">
                <img
                  className="img-fluid"
                  src={require("../../assets/img/portfolio/horizon_badge.png")}
                  alt=""
                />
              </div>
              <div className="img_group">
                <img
                  className="img-fluid"
                  src={require("../../assets/img/portfolio/horizon_top_logo.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 offset-md-2 col_2">
            <h3>Logo</h3>
            <div className="row">
              <div className="col-sm-6">
                <p>CMYK</p>
                <h5>
                  <span>25</span>
                  <span>33</span>
                  <span>59</span>
                  <span>1</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>CMYK</p>
                <h5>
                  <span>71</span>
                  <span>65</span>
                  <span>67</span>
                  <span>77</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>192</span>
                  <span>164</span>
                  <span>119</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>29</span>
                  <span>29</span>
                  <span>27</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>c0a477</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>1d1d1b</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid horizon_section_4">
        <div className="row row_1">
          <div className="col-md-6 text">
            <div className="inner">
              <h3>Nazwa</h3>
              <p>
                Naming to często dość skomplikowany proces. W przypadku tego
                projektu mieliśmy kilka punktów zaczepienia - wszystko dzięki
                temu, że zarówno Szklarska Poręba wraz z okolicami oraz sam
                obiekt posiada wiele charakterystycznych cech, które mogliśmy
                wykorzystać podczas opracowywania koncepcji. Klientowi
                przedstawiliśmy propozycje wynikające z lokalizacji obiektu,
                historii Szklarskiej Poręby jak i takie bazujące na cechach
                samego obiektu czy raczej wrażeń jakie zapewni on swoim gościom.
                Finalna nazwa została wybrana w drugiej rundzie prac. Horizon to
                po prostu horyzont. To dość oczywiste, ale jednocześnie niewiele
                obiektów może pochwalić się takim widokiem jak właśnie Horizon
                Hotel & Resort.
              </p>
              <h3>Logo</h3>
              <p>
                Horizon to horyzont, dlatego też znak, który zaprojektowaliśmy
                nawiązuje do niego w sygnecie, w którym możemy odnaleźć
                symbolizującą go opadającą na krańcach linię. Drobne wcięcie w
                literze H nawiązuje z kolei do górskiej lokalizacji obiektu.
                Całość ma dostojny, ale jednocześnie stosunkowo nowoczesny
                (dzięki prostocie znaku) charakter.
              </p>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/horizon_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row row_2">
          <div className="col-md-6 image">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/horizon_screen2.png")}
              alt=""
            />
          </div>
          <div className="col-md-6 text">
            <div className="inner">
              <h3>Copywriting</h3>
              <p>
                Przygotowując się do zaprojektowania strony internetowej
                musieliśmy jednocześnie zadbać o przygotowanie treści. Finalny
                ich kształt powstał w oparciu o research (dane o Szklarskiej
                Porębie, jej historii, atrakcjach itp.) oraz o dane dotyczące
                inwestycji oraz jej modelu biznesowego.
              </p>
            </div>
          </div>
        </div>
        <div className="row row_3">
          <div className="col-md-6 col-image">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/horizon_screen.png")}
              alt=""
            />
          </div>
          <div className="col-md-6 text">
            <div className="inner">
              <h3>Landing page i serwis internetowy inwestycji</h3>
              <p>
                Wiedzieliśmy, że niebawem będziemy uruchamiać kampanię leadową,
                dlatego do projektu strony lądowania oraz tej finalnej
                podchodziliśmy z dużą uwagą. Mamy bardzo duże doświadczenie w
                branży deweloperskiej oraz inwestycyjnej, jednak mimo to prace
                rozpoczęliśmy od dokładnego przemyślenia oraz zaprojektowania
                makiety serwisu.
              </p>
              <p>
                Projekt graficzny oparliśmy oczywiście o key visual a samą
                stronę, zgodnie z życzeniem Klienta, wdrożyliśmy do WordPress.
              </p>
            </div>
          </div>
        </div>
        <div className="row row_4">
          <div className="col-md-6 text">
            <div className="inner">
              <h3>Kampania on-line, kampania off-line</h3>
              <p>
                Po prostu wiemy jak to robić:) Szczegóły przedstawimy zapewne po
                zakończeniu działań, pomyślnym sprzedaniem całej inwestycji (tak
                jak to mamy w zwyczaju:) ). Na potrzeby kampanii (poza jej
                zaprojektowaniem i realizacją), wykonaliśmy paczki banerów
                statycznych i dynamicznych, oraz projektu reklamy outdoorowej.
              </p>
            </div>
          </div>
          <div className="col-md-6 image">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/horizon_banners.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="container-fluid horizon_section_5">
        <div className="row">
          <div className="col-md-5 interactive_banner">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/img_for_banner.png")}
              alt=""
            />
            <iframe
              src="https://wp.adream.pl/wp-content/themes/adream/assets/banery/horizon/index.html"
              height="800px"
              frameborder="0"
              title="Horizon"
            ></iframe>
          </div>
          <div className="col-md-5 col_banner_info">
            <div className="inner">
              <p>
                Przykład reklamy dynamicznej realizowanej w kampanii Facebook
                ADS.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid horizon_section_7">
        <div className="row">
          <div className="col-md-10">
            <div className="inner">
              <p>
                Na potrzeby wsparcia biura sprzedaży przygotowaliśmy katalog
                opisujący wszystkie walory inwestycji oraz lokalizacji
                turystycznej. Ostateczny kształt merytoryczny jest wynikiem
                bezpośredniego zaangażowania inwestora. Po naszej stronie było
                finalne przygotowanie treści, opracowanie architektury
                informacji oraz prezentacja ich w formie, która będzie spójna z
                towarzyszącemu projektowi Horizon Hotel & Resort stylem. Poza
                katalogiem przygotowaliśmy szereg elementów identyfikacji
                wizualnej jak wizytówki, stopki mailowe, papier firmowy oraz
                teczki.
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/identyfikacja.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="container-fluid horizon_section_6">
        <div className="row">
          <div className="col-md-7 col-image">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/billboard.png")}
              alt=""
            />
          </div>
          <div className="col-md-4 offset-md-1 text">
            <div className="inner">
              <h3>Key Visual</h3>
              <p>
                Hotel Horizon to inwestycja w modelu condo. Oznacza to, że w
                pierwszej fazie projektu poszukiwani są inwestorzy, którzy
                zakupią pokój hotelowy w celach inwestycyjnych (nabywając
                jednocześnie pakiet benefitów właścicielskich). Aby podkreślić
                bezpieczeństwo inwestycji (uznany na rynku deweloper) oraz
                wysoki standard hotelu, postanowiliśmy wykorzystać motyw tarczy
                herbowej, która znalazła swoje miejsce zarówno w logo, jak i w
                motywie przewodnim marki Hotel Horizon.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid horizon_section_8">
        <div className="row">
          <div className="col-md-9">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/billboard_2.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <BanerMDD />
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Horizon;
